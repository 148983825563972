<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.order_no" placeholder="请输入订单" @keyup.enter.native="$refs.table.refresh(true)"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.OrderStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="展示仓库">
              <a-select
                option-label-prop="tt"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                v-model="queryParam.exhibition_warehouse_id"
                placeholder="展示仓库"
                :default-active-first-option="false"
                @search="handleExhibitionWarehouseSearch"
                @change="handleExhibitionWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_exhibition_warehouse" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!--    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>-->

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a @click="handleUpdate(record)" v-if="record.status === 1">现金支付</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a-popconfirm
            v-if="record.status === 1"
            title="确定要取消么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>取消</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

import {
  exhibition_order_create,
  exhibition_order_delete,
  exhibition_order_list,
  exhibition_order_update
} from '@/api/exhibition'
import { exhibition_warehouse_list } from '@/api/exhibition_warehouse'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      exhibition_warehouse_value: '',
      exhibition_goods_value: '',
      bonded_commodity_value: '',
      dataSource_exhibition_warehouse: [],
      dataSource_exhibition_goods: [],
      dataSource_bonded_commodity: [],
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          dataIndex: 'order_no'
        },
        {
          title: '创建人',
          dataIndex: 'create_by'
        },
        {
          title: '展示仓库',
          dataIndex: 'exhibition_warehouse',
          customRender: (text) => text || '-'
        },
        {
          title: '总金额',
          dataIndex: 'total_amount',
          align: 'center',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '支付金额',
          dataIndex: 'payment_amount',
          align: 'center',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '支付方式',
          dataIndex: 'pay_tool',
          customRender: (text) => this.$Dictionaries.PayTool[text]
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.OrderStatus[text]
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return exhibition_order_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    console.log(this.$Dictionaries)
  },
  methods: {
    handleExhibitionWarehouseSearch (value) {
      exhibition_warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_exhibition_warehouse = !value ? [] : result
      })
    },
    handleExhibitionWarehouseChange (value) {
      this.queryParam.exhibition_warehouse_id = value
      this.exhibition_warehouse_value = value
    },
    time (dates, dateStrings) {
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDetail (record) {
      this.$router.push({ path: '/exhibition/order/detail', query: { id: record.id } })
    },
    handleUpdate (record) {
      exhibition_order_update({}, record.id).then(({ data }) => {
        console.log(data)
        this.$refs.table.refresh(true)
      })
    },
    handleDelet (record) {
      exhibition_order_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
      .catch((err) => {
        this.$message.error('网络出错,请联系管理员' + err)
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.total_amount = Math.round(values.total_amount * 100)
          values.payment_amount = Math.round(values.payment_amount * 100)
          exhibition_order_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
